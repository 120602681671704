import * as React from 'react'
import Decimal from 'decimal.js'

import {
  Card,
  Checkbox,
  Col,
  message,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography
} from 'antd'
import _ from 'lodash'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/es/form'
import FormItem from '@ant-design/compatible/es/form/FormItem'

import NumberInput from 'seed-shared-components/lib/components/NumberInput'
import { IFund } from 'seed-shared-components/lib/static-data/assetConfig'
import * as participantsApi from '../../api/participantsApi'

import { IAuthUser } from '../../auth/WithAuthentication'

import { currencySymbol } from '../../utils/currencySymbols'
import {
  IPortalApiWithdrawalAccount,
  IPortalApiWithdrawalRequest
} from '../../withdrawal/withdrawalTypes'
import RestService from '../../RestService'
import { IAssetWithNewProps } from './types'
import { WarningOutlined } from '@ant-design/icons'

const Option = Select.Option

interface IWithdrawalPageProps extends FormComponentProps {
  authUser: IAuthUser
  visible: boolean
  loading: boolean
  asset: string
  getParticipantsNames: (
    participantsCodes: string[]
  ) => Promise<participantsApi.IParticipantsNamesResult>
  onClose: () => void
  onCreate: (data: any) => void
  participantCode: string
  params: any
  restService: RestService
  history: any
  newSelectedAsset?: IAssetWithNewProps
}

interface IWithdrawalPageState {
  accounts: IPortalApiWithdrawalAccount[] | null
  selectedAccountLabel: string
  amount?: {
    errorMsg: string | null
    validateStatus: string | null
    value: number
  }
  accountGroup: string | null
  cryptoWithdrawAgreement: boolean
  limit: number
  loading: boolean
  newWithdrawals: IPortalApiWithdrawalRequest[]
  pendingRequest: boolean
  primaryAccountGroup: string | null
  accountGroups: {
    name: string
    code: string
    available: number
    disabled: boolean
    account_label: string
  }[]
  isAllowedToManageWithdrawals: boolean
  accountLabels: string[]
}

class NewWithdrawalModal extends React.Component<
  IWithdrawalPageProps,
  IWithdrawalPageState
> {
  lastSubmitTime: number
  static DEFAULT_LIMIT = Infinity

  constructor(props: any) {
    super(props)
    this.state = {
      selectedAccountLabel: 'general',
      accounts: null,
      amount: {
        errorMsg: null,
        validateStatus: null,
        value: 20000
      },
      accountGroup: null,
      primaryAccountGroup: null,
      cryptoWithdrawAgreement: false,
      // the first address is the initalValue
      limit: NewWithdrawalModal.DEFAULT_LIMIT,
      loading: true,
      newWithdrawals: [],
      pendingRequest: false,
      accountGroups: [],
      isAllowedToManageWithdrawals: false,
      accountLabels: []
    }
  }

  async componentDidMount() {
    const { authUser, participantCode } = this.props

    this.fetchData()

    const isAllowedToManageWithdrawals = await authUser.isAdmin(participantCode)
    this.setState({ isAllowedToManageWithdrawals })
  }

  async fetchData() {
    const selectedAsset = this.props.params.asset
    const { restService } = this.props
    let accounts: IPortalApiWithdrawalAccount[] = []
    let participant: any = null

    try {
      accounts = await restService
        .route(`withdrawal_accounts/${selectedAsset}`)
        .get<IPortalApiWithdrawalAccount[]>()

      this.setState(() => ({
        accounts
      }))

      // early return if no active accounts
      if (accounts.length === 0) {
        this.setState(() => ({
          loading: false
        }))

        return
      }
    } catch (err) {
      notification.error({
        description: err,
        duration: 0,
        message: 'Failed to fetch data'
      })

      this.setState(() => ({
        accounts: [],
        loading: false
      }))

      return
    }

    let funds: IFund[] = []

    try {
      const [fundsResponse, participantResponse] = await Promise.all<
        IFund[],
        any
      >([
        restService.route('funds').get<IFund[]>(),
        restService.route('basic').get<any>()
      ])

      funds = fundsResponse
      participant = participantResponse
    } catch (e) {
      notification.error({
        description: e,
        duration: 0,
        message: 'Failed to fetch data'
      })

      this.setState(() => ({
        accounts: [],
        loading: false
      }))

      return
    }

    let limit = NewWithdrawalModal.DEFAULT_LIMIT
    const firstAccount = accounts?.find((a) => a.status === 'APPROVED')
    if (firstAccount) {
      limit = firstAccount.withdrawalLimit ?? limit
    }

    const fundsByAsset = funds
      .filter((fund) => {
        return fund.asset.toLowerCase() === selectedAsset.toLowerCase()
      })
      .sort((fundLeft, fundRight) => {
        if (fundLeft.requiresApproval === fundRight.requiresApproval) {
          return 0
        }

        return !fundLeft.requiresApproval ? -1 : 1
      })

    const participantsNamesByCode = await this.props.getParticipantsNames(
      fundsByAsset.map((fund) => fund.account_group)
    )

    const accountGroups = fundsByAsset
      .map((fund) => ({
        code: fund.account_group,
        account_label: fund.account_label,
        available: fund.available,
        name: participantsNamesByCode[fund.account_group],
        disabled: fund.requiresApproval
      }))
      .filter((accountGroup) => accountGroup.available)

    const primaryAccountGroup = accountGroups.filter(
      (ac) => ac.code === participant.primary_account_group
    ).length
      ? participant.primary_account_group
      : null

    this.fetchAccountLabels(primaryAccountGroup)

    this.setState(() => ({
      accounts,
      limit,
      loading: false,
      primaryAccountGroup,
      accountGroups
    }))
  }

  setNewLimit = (id: number): void => {
    if (!this.state.accounts) {
      return
    }
    const address = this.state.accounts.find(
      (a) => a.withdrawalAccountId === id
    )
    const limit = address
      ? address.withdrawalLimit ?? NewWithdrawalModal.DEFAULT_LIMIT
      : NewWithdrawalModal.DEFAULT_LIMIT
    this.setState(() => ({ limit }))
  }

  submitWithdrawalRequest = (e: any) => {
    e.preventDefault()

    if (!this.isAmountValueGreaterThanZero()) {
      return
    }

    if (this.lastSubmitTime >= Date.now() - 1000) {
      this.props.restService.logger.info({
        message: 'Submit pressed within 1 second of previous press, skipping'
      })
      return
    }

    this.lastSubmitTime = Date.now()

    this.props.form.validateFields(async (err: any, values: any) => {
      if (err) {
        return
      }

      this.setState(() => ({ pendingRequest: true }))
      const { params: routeParams, restService } = this.props
      const hide: any = message.loading('Submitting Request', 0)

      try {
        const res = await restService
          .route(`withdrawal_requests`)
          .post<IPortalApiWithdrawalRequest>({
            amount: values.amount,
            currency: routeParams.asset,
            requestor_participant_code: this.props.participantCode,
            withdrawal_account_group: values.from_account_group,
            withdrawal_account_label: values.from_account_label,
            withdrawal_account_id: values.address,
            no_destination_tag: values.memoId === undefined,
            destination_tag: String(values.memoId)
          })

        hide()
        message.success(`Submission Complete!`, 2.5)
        this.props.form.resetFields()

        if (!this.state.accounts) {
          return
        }

        // TODO: just re-fetch data from backend >>>
        // lookup the name, this would normally be joined when querying
        const address = this.state.accounts.find(
          (a) => a.withdrawalAccountId === values.address
        )
        if (!address) {
          return
        }
        res.data.name = address.name
        const newWithdrawals = [res.data, ...(this.state.newWithdrawals || [])]
        // TODO: just re-fetch data from backend <<<
        this.setState(() => ({
          cryptoWithdrawAgreement: false,
          newWithdrawals,
          pendingRequest: false
        }))

        this.props.onCreate(res.data)
      } catch (errorMessage) {
        this.setState(() => ({ pendingRequest: false }))
        hide()
      }
    })
  }

  onCryptoAgreement = (e: any): void => {
    this.setState(() => ({ cryptoWithdrawAgreement: e.target.checked }))
  }

  isAmountValueGreaterThanZero() {
    const amountValue = this.props.form.getFieldValue('amount')

    try {
      // catch issue in ase if passed incompatible value
      return new Decimal(amountValue).greaterThan(0)
    } catch (err) {
      return false
    }
  }

  isAmountValueGreaterThanLimit() {
    const amountValue = this.props.form.getFieldValue('amount')

    try {
      // catch issue in ase if passed incompatible value
      return new Decimal(amountValue).greaterThan(this.state.limit)
    } catch (err) {
      return false
    }
  }

  validateAmount = (field: any, value: string) => {
    const assetUpperCase = String(this.props.params?.asset).toUpperCase()
    const minimum = this.props.newSelectedAsset.withdrawalMinimum

    if (minimum) {
      const amountDecimal = new Decimal(value)
      const minimumDecimal = new Decimal(minimum)

      if (amountDecimal.cmp(minimumDecimal) === -1) {
        return Promise.reject(
          `Minimal amount for ${assetUpperCase} is ${minimum}`
        )
      }
    }

    return Promise.resolve()
  }

  renderNoAccounts() {
    const isFiat = this.props.newSelectedAsset.type === 'Fiat'

    return (
      <div style={{ marginBottom: 24 }}>
        You have no withdrawal accounts. Click{' '}
        <b>Create {isFiat ? 'New Account' : 'New Address'}</b> to add an{' '}
        {isFiat ? 'account' : 'address'} and to initiate a withdrawal.
      </div>
    )
  }

  renderNoApprovedAccounts() {
    const isFiat = this.props.newSelectedAsset.type === 'Fiat'

    return (
      <div style={{ marginBottom: 24 }}>
        You have a pending withdrawal account. Click{' '}
        <b>Manage {isFiat ? 'Withdrawal Accounts' : 'Withdrawal Addresses'}</b>{' '}
        to review its status, and to continue the withdrawal process.
      </div>
    )
  }

  fetchAccountLabels(accountGroup: string) {
    if (this.state.accountGroups.filter((ac) => !ac.disabled)) {
      const { restService } = this.props

      restService
        .route(
          `account_labels?account_group=${accountGroup}&should_have_balance=true`
        )
        .get<string[]>()
        .then((accountLabels) => {
          this.setState({
            accountLabels
          })
        })
        .catch((err) => {
          notification.error({
            description: err,
            duration: 0,
            message: 'Failed to fetch data'
          })

          this.setState(() => ({
            accounts: []
          }))
        })
    }
  }

  renderForm() {
    const isFiat = this.props.newSelectedAsset.type === 'Fiat'
    const { getFieldDecorator } = this.props.form
    const withdrawalAccounts = this.state.accounts || []
    const formItemLayout = {
      labelCol: { offset: 3, span: 12 },
      wrapperCol: { span: 12 }
    }
    const selectedAsset = this.props.params.asset
    const unallocatedAccount = this.state.accountGroups.find(
      (accountGroup) => accountGroup.code === 'UNALLOCATED'
    )

    let defaultSelectedAccountGroup =
      (unallocatedAccount && unallocatedAccount.code) ||
      (this.state.accountGroups.length &&
        this.state.accountGroups[0].code &&
        !this.state.accountGroups[0].disabled)

    if (this.state.primaryAccountGroup) {
      const primaryAccountGroup = this.state.accountGroups.find(
        (group) => group.code === this.state.primaryAccountGroup
      )

      if (!primaryAccountGroup || !primaryAccountGroup.disabled) {
        defaultSelectedAccountGroup = this.state.primaryAccountGroup
      }
    }

    return (
      <Card style={{ marginTop: 14, marginBottom: 24 }}>
        <Form layout="vertical" onSubmit={this.submitWithdrawalRequest}>
          <FormItem {...formItemLayout} label="To Address">
            {getFieldDecorator('address', {
              initialValue: withdrawalAccounts.filter(
                (a) => a.status === 'APPROVED'
              )[0].withdrawalAccountId
            })(
              <Select onChange={this.setNewLimit}>
                {withdrawalAccounts
                  .filter((a) => a.status === 'APPROVED')
                  .map((address) => (
                    <Option
                      key={String(address.withdrawalAccountId)}
                      value={address.withdrawalAccountId}
                    >
                      {address.name}
                    </Option>
                  ))}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="From Account Group">
            {getFieldDecorator(
              'from_account_group',
              {}
            )(
              <Select
                onChange={(e) => {
                  this.setState({ accountGroup: e })
                  this.props.form.setFieldsValue({
                    from_account_group: e
                  })
                  this.fetchAccountLabels(e)
                }}
                showSearch
                filterOption={(input, option) => {
                  const optionLabel =
                    typeof option.children === 'string'
                      ? option.children
                      : typeof (option.children as any)?.props?.children ===
                        'string'
                      ? (option.children as any).props.children
                      : input

                  return (
                    optionLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }}
              >
                {_.uniqBy(this.state.accountGroups, 'code').map(
                  (accountGroup) => {
                    const text = `${accountGroup.name} (${
                      accountGroup.code
                    }) - ${currencySymbol[selectedAsset] || ''}`

                    if (accountGroup.disabled) {
                      return (
                        <Option
                          value={`${accountGroup.code}`}
                          disabled={true}
                          key={`${accountGroup.code}_${accountGroup.available}`}
                        >
                          <Tooltip title="Cannot withdraw directly from this account as the platform operator has restricted withdrawals. Please unallocate funds from this platform and then withdraw.">
                            {text}
                          </Tooltip>
                        </Option>
                      )
                    } else {
                      return (
                        <Option
                          value={`${accountGroup.code}`}
                          key={`${accountGroup.code}_${accountGroup.available}`}
                        >
                          {text}
                        </Option>
                      )
                    }
                  }
                )}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="From Account Label">
            {getFieldDecorator('from_account_label', {
              initialValue: !!defaultSelectedAccountGroup ? 'general' : null
            })(
              <Select
                showSearch
                onChange={(v) => this.setState({ selectedAccountLabel: v })}
                filterOption={(input, option) => {
                  const optionLabel =
                    typeof option.children === 'string'
                      ? option.children
                      : typeof (option.children as any)?.props?.children ===
                        'string'
                      ? (option.children as any).props.children
                      : input

                  return (
                    optionLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }}
              >
                {(this.state.accountLabels || []).map((accountLabel) => {
                  return (
                    <Option value={accountLabel} key={accountLabel}>
                      {accountLabel}
                    </Option>
                  )
                })}
              </Select>
            )}
          </FormItem>
          <Row style={{ marginBottom: 24, width: '100%', display: 'flex' }}>
            <Col xs={12}>
              <Typography.Text>Available amount:</Typography.Text>
            </Col>
            <Col xs={12} style={{ display: 'flex' }}>
              <Typography.Text
                style={{
                  textAlign: 'right',
                  width: '100%'
                }}
              >
                {currencySymbol[selectedAsset]}{' '}
                {this.state.accountGroups.find(
                  (ag) =>
                    ag.code === this.state.accountGroup &&
                    ag.account_label === this.state.selectedAccountLabel
                )?.available || '0.00'}
              </Typography.Text>
            </Col>
          </Row>
          <FormItem
            {...formItemLayout}
            label="Amount"
            extra={
              this.isAmountValueGreaterThanLimit() ? (
                <>
                  Withdrawal request amount cannot be larger than the limit on
                  the withdrawal account.
                  <br />
                  Selected account has limit: {this.state.limit}{' '}
                  {selectedAsset.toUpperCase()}
                </>
              ) : null
            }
          >
            {getFieldDecorator('amount', {
              rules: [
                { required: true },
                { validator: this.validateAmount } as any
              ]
            })(
              <NumberInput
                acceptDecimal={true}
                allowLeadingZero={true}
                decimalScale={
                  selectedAsset ? this.props.newSelectedAsset.precision : 2
                }
              />
            )}
          </FormItem>
          {selectedAsset && selectedAsset.toUpperCase() === 'XLM' && (
            <div>
              <p>
                Stellar (XLM) wallets require a minimum balance of 2.5 XLM. More
                information can be found{' '}
                <a
                  href="https://zerohash.zendesk.com/hc/en-us/articles/360042577734"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>
            </div>
          )}
          {selectedAsset && selectedAsset.toUpperCase() === 'XRP' && (
            <div>
              <p>
                Ripple (XRP) wallets require a minimum balance of 25 XRP. More
                information can be found{' '}
                <a
                  href="https://zerohash.zendesk.com/hc/en-us/articles/360047927194-XRP-Minimums"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>
            </div>
          )}
          {!isFiat && (
            <div>
              <FormItem wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                <div>
                  <p>
                    <WarningOutlined style={{ fontSize: 16, color: 'red' }} />{' '}
                    Note: You acknowledge that Zero Hash LLC and its affiliates
                    are not liable for withdrawals made to incorrectly entered
                    wallet addresses. Be sure to double check that you have
                    entered the correct wallet address for the type of digital
                    asset being withdrawn. On-chain transactions may be
                    irreversible.
                  </p>
                </div>
              </FormItem>

              <FormItem wrapperCol={{ span: 12, offset: 12 }}>
                <Checkbox
                  checked={this.state.cryptoWithdrawAgreement}
                  onChange={this.onCryptoAgreement}
                >
                  I understand the risks.
                </Checkbox>
              </FormItem>
            </div>
          )}
        </Form>
      </Card>
    )
  }

  render() {
    const selectedAsset = this.props.params.asset
    const isAmountValueGreaterThanZero = this.isAmountValueGreaterThanZero()
    const withdrawalAccounts = this.state.accounts || []
    const hasNoAccounts = withdrawalAccounts.length === 0
    const hasApprovedAccounts =
      withdrawalAccounts.filter((a) => a.status === 'APPROVED').length > 0
    const isAmountValueGreaterThanLimit = this.isAmountValueGreaterThanLimit()
    const isFiat = this.props.newSelectedAsset.type === 'Fiat'

    const submitButtonProps = isFiat
      ? {
          disabled:
            !this.state.isAllowedToManageWithdrawals ||
            !isAmountValueGreaterThanZero
        }
      : {
          disabled:
            !this.state.cryptoWithdrawAgreement ||
            !this.state.isAllowedToManageWithdrawals ||
            !isAmountValueGreaterThanZero ||
            isAmountValueGreaterThanLimit
        }

    return (
      <Modal
        title={`Withdraw ${selectedAsset.toUpperCase()}`}
        visible={this.props.visible}
        width={850}
        confirmLoading={this.state.pendingRequest}
        onCancel={this.props.onClose}
        onOk={this.submitWithdrawalRequest}
        okText="Submit Withdrawal"
        okButtonProps={submitButtonProps}
      >
        <Spin spinning={this.state.pendingRequest} style={{ width: 850 }}>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            All withdrawals must be made to whitelisted{' '}
            {isFiat ? 'accounts' : 'addresses'}. You must first add a withdrawal{' '}
            {isFiat ? 'account' : 'address'} and have it approved by another
            administrator before you may request a withdrawal to it. Please
            refer to our FAQ for more info withdrawal{' '}
            {isFiat ? 'accounts' : 'addresses'}:{' '}
            <a
              href="https://zerohash.zendesk.com/hc/en-us/articles/360008817314-How-are-withdrawal-accounts-set-up-"
              target="_blank"
            >
              How are withdrawal accounts set up?
            </a>{' '}
          </div>

          <div>
            {!this.state.loading && (
              <>
                {hasNoAccounts && this.renderNoAccounts()}
                {hasApprovedAccounts && this.renderForm()}
                {!hasApprovedAccounts && this.renderNoApprovedAccounts()}
              </>
            )}

            {this.state.loading && (
              <Card style={{ width: 800, height: 350 }} loading={true} />
            )}
          </div>
        </Spin>
      </Modal>
    )
  }
}

const WithdrawModalWithForm = Form.create<IWithdrawalPageProps>()(
  NewWithdrawalModal
)
export default WithdrawModalWithForm
